import Login from './Authentication/login';
import ForgotPassword from './Authentication/forgotpassword';
import Maintenance from './Authentication/maintenance';
import UserList from "./Pages/User/list";
import UserDetails from "./Pages/User/details";
import OpinionList from "./Pages/Opinion/list";
import OpinionDetails from "./Pages/Opinion/details";
import CommentList from "./Pages/Comment/list";
import AgoraList from "./Pages/Agora/list";
import AgoraOpinions from "./Pages/Agora/opinion";
import OpinionMembre from "./Pages/Agora/membre";
import Profile from "./Pages/profile";
import ModerateurList from "./Pages/Moderateur/list";
import ReportList from "./Pages/Report/list";
import ReportDetails from "./Pages/Report/details";
import AwardList from "./Pages/Award/list";
import VerificationDemandList from "./Pages/VerificationDemand/list";
import VerificationDemandDetails from "./Pages/VerificationDemand/details";
import UserBadgeStats from "./Pages/Stats/userBadge";
import SubscriptionList from "./Pages/Subscription/list";
import Dashboard from "./Pages/dashboard";
import Authorization from "./Pages/Authorization/list";
import AuthorizationRequestList from "./Pages/Authorization/request";
import HolidayBalanceList from "./Pages/HolidayBalance/list";
import Hub from "./Pages/hub";
import Setting from "./Pages/Setting";
import EventList from './Pages/Event/list'
import Logs from "./Pages/logs";
import ListLive from "./Pages/Live/list";

const Routes = [
    {
        path: "/",
        name: 'dashboard',
        exact: true,
        pageTitle: "Tableau de bord",
        component: Dashboard
    },
    {
        path: "/authorizations",
        name: 'authorizations',
        exact: true,
        pageTitle: "Authorizations",
        component: Authorization
    },
    {
        path: "/authorization-requests",
        name: 'authorization-requests',
        exact: true,
        pageTitle: "Authorization requests",
        component: AuthorizationRequestList
    },
    {
        path: "/users",
        name: 'users',
        exact: true,
        pageTitle: "Users",
        component: UserList
    },
    {
        path: "/lives",
        name: 'lives',
        exact: true,
        pageTitle: "Lives",
        component: ListLive
    },
    {
        path: "/users/:id",
        name: 'user.details',
        exact: true,
        pageTitle: "Details",
        component: UserDetails
    },
    {
        path: "/users/:id/opinions",
        name: 'user.opinions',
        exact: true,
        pageTitle: "Opinions",
        component: UserDetails
    },
    {
        path: "/users/:id/agoras",
        name: 'user.agoras',
        exact: true,
        pageTitle: "Agoras",
        component: UserDetails
    },
    {
        path: "/users/:id/comments",
        name: 'user.comments',
        exact: true,
        pageTitle: "Comments",
        component: UserDetails
    },
    {
        path: "/users/:id/followers",
        name: 'user.followers',
        exact: true,
        pageTitle: "Followers",
        component: UserDetails
    },
    {
        path: "/users/:id/subscriptions",
        name: 'user.subscriptions',
        exact: true,
        pageTitle: "Subscriptions",
        component: UserDetails
    },
    {
        path: "/users/:id/friends",
        name: 'user.friends',
        exact: true,
        pageTitle: "Friends",
        component: UserDetails
    },
    {
        path: "/opinions",
        name: 'opinions',
        exact: true,
        pageTitle: "Opinions",
        component: OpinionList
    },
    {
        path: "/opinions/:id",
        name: 'opinions.details',
        exact: true,
        pageTitle: "Opinions",
        component: OpinionDetails
    },
    {
        path: "/comments",
        name: 'comments',
        exact: true,
        pageTitle: "Commentaires",
        component: CommentList
    },
    {
        path: "/agoras",
        name: 'agoras',
        exact: true,
        pageTitle: "Agoras",
        component: AgoraList
    },
    {
        path: "/agoras/:id/opinions",
        name: 'agoras.opinions',
        exact: true,
        pageTitle: "Agoras",
        component: AgoraOpinions
    },
    {
        path: "/agoras/:id/members",
        name: 'agoras.members',
        exact: true,
        pageTitle: "Agoras",
        component: OpinionMembre
    },
    {
        path: "/reports",
        name: 'reports',
        exact: true,
        pageTitle: "Report",
        component: ReportList
    },
    {
        path: "/reports/:id",
        name: 'reports.details',
        exact: true,
        pageTitle: "Report",
        component: ReportDetails
    },
    {
        path: "/verifications",
        name: 'verifications',
        exact: true,
        pageTitle: "Verification",
        component: VerificationDemandList
    },
    {
        path: "/stats/users_badges",
        name: 'user_badge_stats',
        exact: true,
        pageTitle: "User badge states",
        component: UserBadgeStats
    },
    {
        path: "/verifications/:id/details",
        name: 'verifications.details',
        exact: true,
        pageTitle: "Verification",
        component: VerificationDemandDetails
    },
    {
        path: "/awards",
        name: 'awards',
        exact: true,
        pageTitle: "Awards",
        component: AwardList
    },
    {
        path: "/subscriptions",
        name: 'subscriptions',
        exact: true,
        pageTitle: "Subscriptions",
        component: SubscriptionList
    },
    {
        path: "/login",
        name: 'login',
        exact: true,
        pageTitle: "Login",
        component: Login
    },
    {
        path: "/profile",
        name: 'profile',
        exact: true,
        pageTitle: "Profile",
        component: Profile
    },
    {
        path: "/moderateurs",
        name: 'admins',
        exact: true,
        pageTitle: "Admin",
        component: ModerateurList
    },
    {
        path: "/forgotpassword",
        name: 'forgotpassword',
        exact: true,
        pageTitle: "Tables",
        component: ForgotPassword
    },
    {
        path: "/maintenance",
        name: 'maintenance',
        exact: true,
        pageTitle: "Maintenance",
        component: Maintenance
    },
    {
        path: "/hr/holidays_balance",
        name: 'holidays_balance',
        exact: true,
        pageTitle: "Soldes de conges",
        component: HolidayBalanceList
    },
    {
        path: "/hubs",
        name: 'hub',
        exact: true,
        pageTitle: "Hub",
        component: Hub
    },
    {
        path: "/settings",
        name: 'settings',
        exact: true,
        pageTitle: "Settings",
        component: Setting
    },
    {
        path: "/events",
        name: 'events',
        exact: true,
        pageTitle: "Events",
        component: EventList
    },
    {
        path: "/logs",
        name: 'logs',
        exact: true,
        pageTitle: "Logs",
        component: Logs
    },

];

export default Routes;