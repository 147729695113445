import { firestoreDB } from './../../../services/Firebase/firebaseConfig'
import { collection, limit, onSnapshot, orderBy, query, where, updateDoc, doc } from 'firebase/firestore'
import React, {useState, useEffect} from "react";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {getUserFullName, truncate} from "../../../utils";
import moment from "moment";

const ListLive = ({history, location, match, ...props}) => {

    const [lives, setLives] = useState([]);
    const [fetching, setFetching] = useState(true);

    const handleEndLive = async (live_channel_name) => {

        if(!window.confirm('Confirmer l\'arrêt du live ?'));

        try {
            const refDoc = doc(
                firestoreDB,
                'myopinion',
                'lives',
                'channels',
            live_channel_name
        )

            await updateDoc(refDoc, { live_finished: true }).then(() => {
                console.log('Live finished.');
            }).catch((error) => {
                console.error('Error live finished:', error);
            });
        } catch (error) {
            console.error('Error live finished:', error);
        }
    }

    useEffect(() => {
        const q = query(
            collection(
                firestoreDB,
                'myopinion',
                'lives',
                'channels'
            ),
            where("live_finished", "==", false)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setLives(data);
            setFetching(false);
        });

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Lives encours" />

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">

                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing5">
                                <tbody>
                                {lives?.map(live => (
                                    <tr key={live?.id}>
                                        <td className="w60">
                                            <img
                                                src={live?.user?.profile?.prof_picture || MyOpinionLogo}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title=""
                                                alt="Avatar"
                                                className="w35 rounded"
                                                data-original-title="Avatar Name" />
                                        </td>
                                        <td>
                                            <span>{getUserFullName(live?.user, true)}</span>
                                        </td>
                                        <td>
                                            <span>{truncate(live?.live_description, 50) || 'Aucune description'}</span>
                                        </td>
                                        <td>
                                            <span>{moment(live?.live_start_at, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY à HH:mm")}</span>
                                        </td>
                                        <td>
                                            <button className="btn btn-outline-danger btn-sm font-weight-bold mr-1" onClick={() => handleEndLive(live?.live_channel_name)}>
                                                <i className="fa fa-stop-circle"></i>{' '}
                                                Stopper
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            )}
        </div>
    );

};

export default ListLive;